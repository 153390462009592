import React from "react";
import styled from "styled-components";

// Layout
import Layout from "../components/layout";
import Contain from "../components/contain";
import Cta from "../components/cta";
import Seo from "../components/seo";

// Components
import HeroPage from "../components/hero-page";
import Heading from "../components/heading";
import Video from "../components/video";

const TestimonialsLayout = styled.div`
  padding: 0 1rem;
  column-count: 6;
  column-gap: 2rem;

  @media (max-width: 1920px) {
    column-count: 4;
  }

  @media (max-width: 1280px) {
    column-count: 3;
  }

  @media (max-width: 786px) {
    column-count: 2;
  }

  @media (max-width: 640px) {
    column-count: 1;
  }
`;

const Item = styled.div`
  display: inline-block;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
  border-radius: 1rem;
  background: #fff;
  padding: 0.5rem;
  margin: 0 0 2rem;

  img {
    display: block;
    border-radius: 1rem;
  }
`;

export default class Testimonials extends React.Component {
  state = {
    slides: [],
  };

  componentDidMount() {
    let rows = [];
    const auLinks =
      "https://quickpage-assets.s3.amazonaws.com/public/marketing/testimonials/au/";
    const auCount = 15; // 1 extra

    for (var i = 1; i < auCount; i++) {
      rows.push({
        type: "image",
        url: `${auLinks}${i}.jpg`,
      });
    }

    const videos = [
      {
        url:
          "https://quickpage-prod.s3.amazonaws.com/media/E946E252-A882-49BD-D210-64A8E1721AF0/mEmk6cGTSgjgN2tfT.mp4-web.mp4",
        poster:
          "https://quickpage-prod.s3.amazonaws.com/media/E946E252-A882-49BD-D210-64A8E1721AF0/mEmk6cGTSgjgN2tfT.mp4-poster-00001.png",
        playVideo: true,
      },
      {
        url:
          "https://quickpage-prod.s3.amazonaws.com/media/39513134-A3CD-4678-84C4-F3F7E4B34C77/eyoLoLcHZBFe3kbtM.qt-web.mp4",
        poster:
          "https://quickpage-prod.s3.amazonaws.com/media/39513134-A3CD-4678-84C4-F3F7E4B34C77/eyoLoLcHZBFe3kbtM.qt-poster-00001.png",
      },
      {
        url:
          "https://quickpage-prod.s3.amazonaws.com/media/7D0AA372-811D-41F8-AE06-E09332BB164F/1.mp4-web.mp4",
        poster:
          "https://quickpage-assets.s3.amazonaws.com/marketing/posters/poster-tw.jpg",
      },

      {
        url:
          "https://dburs8kx36lyd.cloudfront.net/media/1626DEEF-FD10-46A4-84D0-CD92BCBF8CA2/5tMfJE3CZywcRBZot.mp4-web.mp4",
        poster:
          "https://dburs8kx36lyd.cloudfront.net/media/1626DEEF-FD10-46A4-84D0-CD92BCBF8CA2/5tMfJE3CZywcRBZot.mp4-poster-00001.png",
      },
      {
        url:
          "https://quickpage-prod.s3.amazonaws.com/media/4AEFB45C-C2A2-4E0A-F2D9-7DC578D95E44/gary-pollart-video.mp4-web.mp4",
        poster:
          "https://quickpage-prod.s3.amazonaws.com/media/4AEFB45C-C2A2-4E0A-F2D9-7DC578D95E44/gary-pollart-video.mp4-poster-00001.png",
        type: "video",
      },
    ];

    rows = [...rows, ...videos];

    const shuffled = rows
      .map((a) => ({ sort: Math.random(), value: a }))
      .sort((a, b) => a.sort - b.sort)
      .map((a) => a.value);

    this.setState({ slides: shuffled });
  }

  render() {
    const { slides } = this.state;

    return (
      <Layout location={this.props.location}>
        <Seo
          title="Testimonials | Quickpage"
          description="Follow up better. Close deals faster. With video."
          keywords="video, email, video email"
          url="https://kintz.quickpage.io/testimonials"
        />
        <HeroPage>
          <Contain>
            <Heading textCenter>Testimonials</Heading>
          </Contain>
        </HeroPage>

        <TestimonialsLayout>
          {slides.map((e, i) => (
            <Item key={`t+${i}`}>
              {e.type === "image" ? (
                <img src={e.url} />
              ) : (
                <Video
                  url={e.url}
                  poster={e.poster}
                  positionStatic={true}
                  autoplay={true}
                />
              )}
            </Item>
          ))}
        </TestimonialsLayout>

        <Cta location={this.props.location} />
      </Layout>
    );
  }
}
